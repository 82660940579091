import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook1/image/imgPortadaEbook1.png"
import img1 from "@components/pageEbook/ebooks/ebook1/image/imgCard1.png"
import img2 from "@components/pageEbook/ebooks/ebook1/image/imgCard2.png"
import img3 from "@components/pageEbook/ebooks/ebook1/image/imgCard3.png"
import slider1 from "@components/pageEbook/ebooks/ebook1/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook1/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook1/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook1/image/imgSilder4.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Ventas por teléfono: <br /> Los mejores speeches y técnicas
    </h1>
  </>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      Las televentas generan un contacto más humano. Por eso siguen
      siendo efectivas pese a los nuevos canales digitales y al constante cambio
      del comportamiento del usuario.
    </p>
    <br />
    <p>
      Como parte de optimizar estas campañas, el siguiente e-book ayudará a tu
      empresa a mejorar tanto las técnicas como el speech de ventas telefónicas.
    </p>
  </div>
)

const textOne = (
  <p>
    Parte de las estrategias para televentas está en mejorar el speech de los
    agentes. Por eso, es importante su constante monitoreo para saber si son
    efectivos.
    <br /> <br />
    Antes de realizar cambios o actualizar el guion se debe establecer objetivos
    y metas que se logrará alcanzar con su implementación.
  </p>
)
const textTwo = (
  <p>
    Existen innumerables tipos de guiones para las televentas. Sin embargo, la
    idea principal es adaptar estos ejemplos según a las necesidades del negocio
    y de los clientes.
    <br /> <br />
    Por eso, este e-book te brindará 3 ejemplos únicos que podrán ser adaptados
    a tus campañas de ventas y los tipos de productos que ofrezcas.
  </p>
)
const textThree = (
  <p>
    Los usuarios de ahora son más digitales y manejan mayor información de los
    productos de su preferencia. Por eso, las técnicas de televentas deben ser
    más directas.
  </p>
)

const data = {
  start: {
    support: "Ebook | Televentas",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-1",
    link: linkForm,
    href: "/ebook-1.pdf",
    brochure: "ebook-televentas",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "Pasos para armar un guion de ventas",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Ejemplos de speech para ventas por teléfono",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Técnicas de ventas por teléfono",
        text: textThree,
        photo: img3,
        class:  true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Perfeccionar tus estrategias de televentas en épocas de crisis.",
      },
      {
        key: 2,
        text: "Armar correctamente un guion para tus campañas de televentas.",
      },
      {
        key: 3,
        text: "Reconocer los tipos de televentas con sus respectivos ejemplos.",
      },
      {
        key: 4,
        text: "Aplicar las mejores técnicas de ventas por teléfono.",
      },
      {
        key: 5,
        text: "Mejorar las técnicas y el speech de ventas telefónicas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
