
import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook1/dataPageEbook1"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        {" "}
        [Ebook] Ventas por teléfono: Speech y técnicas para triunfar
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/ventas-telefono-ebook/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="¿Quieres aumentar tus ventas por teléfono? Te contamos como hacer ventas por teléfono efectivas y a preparar speech para ventas por teléfono."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content=" [Ebook] Ventas por teléfono: Speech y técnicas para triunfar"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/ventas-telefono-ebook/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Ebook/og-imgEbook1.jpg`}
      />
      <meta
        property="og:description"
        content="¿Quieres aumentar tus ventas por teléfono? Te contamos como hacer ventas por teléfono efectivas y a preparar speech para ventas por teléfono."
      />
    </Helmet>
    <TemplatePageEbook ebook="Televentas" location={location} typeEbook="ebook1" 
    clickHereHref="https://beexcc.com/blog/noticias-de-televentas/"
    data={data}
      />
  </div>
)

export default IndexPage
